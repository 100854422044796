import { API_BASE_PATH } from './constants'
import { PaidPlansError } from './paidPlansError'
import { mapApiOrderToCorvidOrder } from './ordersMapper'

interface ApiUrl {
	protocol: string
	hostname: string
}

export class PricingPlansApi {
	constructor(private getInstanceHeader: () => string, private apiUrl: ApiUrl) {}

	createOrder = async (planId: string) => {
		const response = await this.httpApi.post('/orders', { planId, useWixPay: true })

		return {
			orderId: response.orderId as string,
			wixPayOrderId: response.wixPayOrderId as string | undefined,
		}
	}

	cancelOrder = async (orderId: string) => {
		await this.httpApi.post(`/orders/${orderId}/cancel`, { orderId })
	}

	getCurrentMemberOrders = async (limit = 50, offset = 0) => {
		const response = await this.httpApi.get(`/orders/my-orders?limit=${limit}&offset=${offset}`)
		return response.orders.map(mapApiOrderToCorvidOrder)
	}

	private httpApi = {
		get: (url: string) => this.sendRequest(url, 'get'),
		post: (url: string, body: object) => this.sendRequest(url, 'post', body),
	}

	private async sendRequest(url: string, method: 'get' | 'post', body?: object): Promise<any> {
		const requestOptions = {
			method,
			headers: {
				Authorization: this.getInstanceHeader(),
			},
			body: body ? JSON.stringify(body) : undefined,
		}

		const apiBaseUrl = `${this.apiUrl.protocol}//${this.apiUrl.hostname}${API_BASE_PATH}`

		const response = await fetch(`${apiBaseUrl}${url}`, requestOptions).catch((e) => {
			throw new PaidPlansError(e.status, e.message)
		})

		if (!response.ok) {
			await response
				.text()
				.then(tryParsingJsonMessage)
				.then((message) => {
					throw new PaidPlansError(response.status, message)
				})
		}

		return response.json()
	}
}

function tryParsingJsonMessage(text: string) {
	try {
		return JSON.parse(text).message || text
	} catch (e) {
		return text
	}
}
