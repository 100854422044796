import {
	namespace,
	PaidPlansWixCodeSdkFactoryInitialState,
	PaidPlansWixCodeSdkHandlers,
	PaidPlansWixCodeSdkWixCodeApi,
} from '..'
import { PricingPlansApi } from './api'
import { APP_DEF_ID } from './constants'
import { validateGuid } from './validation'

import { PlatformEnvData, PlatformUtils } from '@wix/thunderbolt-symbols'

export function PaidPlansSdkFactory(
	_initialState: PaidPlansWixCodeSdkFactoryInitialState,
	_handlers: PaidPlansWixCodeSdkHandlers,
	{ sessionServiceApi, wixCodeNamespacesRegistry }: PlatformUtils,
	{ url }: PlatformEnvData
): { [namespace]: PaidPlansWixCodeSdkWixCodeApi } {
	const getInstanceHeader = () => sessionServiceApi.getInstance(APP_DEF_ID)
	const apiHost = { protocol: url.protocol, hostname: url.hostname }
	const api = new PricingPlansApi(getInstanceHeader, apiHost)

	async function ensureMemberIsLoggedIn() {
		const siteMembers = wixCodeNamespacesRegistry.get('user')
		if (!siteMembers.currentUser.loggedIn) {
			await siteMembers.promptLogin()
		}
	}

	return {
		[namespace]: {
			async getCurrentMemberOrders(limit?: number, offset?: number) {
				await ensureMemberIsLoggedIn()
				return api.getCurrentMemberOrders(limit, offset)
			},

			async orderPlan(planId: string) {
				validateGuid(planId)
				await ensureMemberIsLoggedIn()

				return api.createOrder(planId)
			},

			async cancelOrder(orderId: string) {
				validateGuid(orderId)
				await ensureMemberIsLoggedIn()

				return api.cancelOrder(orderId)
			},

			async purchasePlan(planId: string) {
				validateGuid(planId)
				await ensureMemberIsLoggedIn()

				const wixPay = wixCodeNamespacesRegistry.get('pay')

				const { orderId, wixPayOrderId } = await api.createOrder(planId)

				if (!wixPayOrderId) {
					return { orderId }
				}

				const { status: wixPayStatus } = await wixPay.startPayment(wixPayOrderId, { showThankYouPage: true })

				return {
					orderId,
					wixPayOrderId,
					wixPayStatus,
				}
			},
		},
	}
}
